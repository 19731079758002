import './App.css'
import {BrowserRouter as Router , Routes ,Route } from 'react-router-dom'
import Home from './pages/Home'
import { useState, useEffect } from 'react'
import About from './pages/About'
import Contact from './pages/Contact'
import OrderInter from './component/OrderInter'
import Fat from './pages/Fat'
import Slim from './pages/Slim'
import Hair from './pages/Hair'
import Sex from './pages/Sex'


function App() {




  

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path= '/' element= {<Home />} />
          <Route exact path= '/about' element= {<About />} />
          <Route exact path= '/contact' element= {<Contact />} />
          
          <Route exact path= '/order' element= {<OrderInter />} />
          <Route exact path= '/fat_products' element= {<Fat />} />
          <Route exact path= '/slim_products' element= {<Slim />} />
          <Route exact path= '/sexual_products' element= {<Sex />} />
          <Route exact path= '/hair_products' element= {<Hair />} />
          
        </Routes>
      </Router>
    </div>
  );
}


export default App;



