import React from 'react'
import OrderInter from '../component/OrderInter'

function Fat() {
  return (
    <div>
        <OrderInter page= {"fat"} />
    </div>
  )
}

export default Fat