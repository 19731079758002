import Footer from "../component/Footer"
import Nav from "../component/Nav"
import '../styles/Abc.css'
import { motion } from "framer-motion"

function About() {
  return (
    <div className= 'About'>
        <Nav active= {"about"} />
        <div className="About_main">
            <motion.div className="About_main_content" transition= {{ duration: 0.5 }} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 1, opacity: 1}}>
                <motion.p id= "About_title" style= {{marginTop: 0}} transition= {{ delay: .5, duration: .5 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}} >مقدمة عن الشركة</motion.p>
                <motion.p transition= {{ delay: 1, duration: .25 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>
                تأسست شركتنا لتلبية احتياجات المرأة المعاصرة، حيث نقدم مجموعة متكاملة من المنتجات المصممة خصيصًا لتعزيز جمالها وثقتها بنفسها. نحن نؤمن بأن كل امرأة تستحق أن تشعر بالجمال والقوة، لذا نسعى لتقديم الأفضل في كل ما نقدمه.
                </motion.p>
                <motion.p id= "About_title" transition= {{ delay: 1.25, duration: .5 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}} >منتجات التسمين والتنحيف</motion.p>
                <motion.p transition= {{ delay: 1.75, duration: .25 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>
                تقدم شركتنا مجموعة متنوعة من المنتجات المخصصة للتسمين والتنحيف، والتي تمت صياغتها بعناية باستخدام مكونات طبيعية فعالة. منتجاتنا للتسمين تساعد على زيادة الوزن بطريقة صحية ومتوازنة، بينما تعمل تركيبات التنحيف على تعزيز عملية حرق الدهون وتحسين اللياقة البدنية، مما يساهم في تحقيق أهدافك الجمالية دون أي آثار جانبية ضارة.
                </motion.p>
                <motion.p id= "About_title" transition= {{ delay: 2, duration: .5 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>مواد الشعر</motion.p>
                <motion.p transition= {{ delay: 2.5, duration: .25 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}> 
                ندرك أن الشعر هو رمز جمال المرأة، لذا نقدم مجموعة شاملة من مواد العناية بالشعر، والتي تشمل الشامبو، البلسم، والزيوت الطبيعية. تركيباتنا الغنية بالمكونات الطبيعية تعمل على تغذية الشعر وتعزيز قوته، مما يساعد على تحقيق مظهر صحي ولامع.
                </motion.p>
                <motion.p id= "About_title" transition= {{ delay: 2.75, duration: .5 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>القوة الجنسية</motion.p>
                <motion.p transition= {{ delay: 3.25, duration: .25 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>
                نسعى أيضًا إلى تعزيز الصحة الجنسية لدى النساء من خلال منتجاتنا الفريدة التي تدعم الرغبة الجنسية وتحسن من الصحة العامة. تعتمد تركيباتنا على مكونات طبيعية ثبت فعاليتها، مما يساهم في زيادة الثقة وتعزيز العلاقات الحميمة.
                </motion.p>
                <motion.p id= "About_title" transition= {{ delay: 3.5, duration: .5 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>خلاصة</motion.p>
                <motion.p transition= {{ delay: 4, duration: .25 }} initial={{x: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}} >
                إننا في شركتنا نلتزم بتقديم منتجات عالية الجودة تلبي جميع احتياجات المرأة، ونهدف إلى تمكين النساء من الشعور بالجمال والثقة في أنفسهن. انضمّي إلينا في رحلتك نحو الجمال والصحة!
                </motion.p>
            </motion.div>
        </div>
        <Footer />
    </div>
  )
}

export default About