import Footer from "./Footer"
import Nav from "./Nav"
import '../styles/Order.css'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css"
import fatMain from '../images/fat/fat_main.jpg'
import fatUno from '../images/fat/fat_uno.jpg'
import fatDos from '../images/fat/fat_dos.jpg'
import fatTres from '../images/fat/fat_tres.jpg'
import fatCuatro from '../images/fat/fat_cuatro.jpg'
import slimMain from '../images/slim/slim_main.jpg'
import slimUno from '../images/slim/slim_uno.jpg'
import sexMain from '../images/sex/sex_main.jpg'
import sexUno from '../images/sex/sex_uno.jpg'
import sexDos from '../images/sex/sex_dos.jpg'
import sexTres from '../images/sex/sex_tres.jpg'
import hairMain from '../images/hair/hair_main.jpg'
import hairUno from '../images/hair/hair_uno.jpg'
import hairDos from '../images/hair/hair_dos.jpg'
import { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Button  from "@mui/material/Button"
import Axios from 'axios'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'


function OrderInter( {page, main, uno, dos, tres, cuatro} ) {

    const [images, setImages]= useState([])
    const [wilayas, setWilayas]= useState([])
    const [produits, setProduits]= useState([])
    const [wilayaFId, setWilayaFId]= useState("")
    const [produitFId, setProduitFId]= useState("")
    const [nom, setNom]= useState("")
    const [prenom, setPrenom]= useState("")
    const [numTel, setNumTel]= useState("")
    const [qte, setQte]= useState(0)
    const [total, setTotal]= useState(0)
    const [msgSucc, setMsgSucc]= useState(false)
    const [msgErr, setMsgErr]= useState(false)

    const dec= ()=> {
        if(qte> 0){
            setQte(old=> old - 1)
        }else {
            setQte(0)
        }
    }

    const getWilayas= ()=> {
        Axios.get(`https://gnmc-dz.com/backtest/getWilayas`)
        .then(res=> {
            setWilayas(res.data)
        })
        .catch(err=> {
            console.log(err)
        })
    }
    const getProduits= ()=> {
        Axios.post(`https://gnmc-dz.com/backtest/getGamme`, {gamme: page})
        .then(res=> {
            setProduits(res.data)
        })
        .catch(err=> {
            console.log(err)
        })
    }

    const addCommande= (e)=> {
        e.preventDefault()
            Axios.post(`http://localhost:3030/addCommande`, 
            {nom: nom, prenom: prenom, numTel: numTel, wilayaFId: wilayaFId, produitFId: produitFId, qte: qte})
            .then(res=> {
                setMsgSucc(true)
                setNom("")
                setPrenom("")
                setNumTel("")
                setWilayaFId("")
                setProduitFId("")
                setQte(0)
                setTotal(0)
                setTimeout(()=> {
                    setMsgSucc(false)
                }, 5000)
            })
            .catch(err=> {
                console.log("ll")
                setMsgErr(true)
                setTimeout(()=> {
                    setMsgErr(false)
                }, 5000)
            })
        
    }


    useEffect(()=> {
        getWilayas()
        getProduits()
    }, [])


/*
    useEffect(() => {
        window.scrollTo( {top: "0", behavior: "smooth"} )
    }, [])
*/
    useEffect(()=> {
        if(page=== "fat") {
            setImages([
                {original: fatMain, thumbnail: fatMain},
                {original: fatUno, thumbnail: fatUno},
                {original: fatDos, thumbnail: fatDos},
                {original: fatTres, thumbnail: fatTres},
                {original: fatCuatro, thumbnail: fatCuatro}
            ])
        }else if(page=== "slim") {
            setImages([
                {original: slimMain, thumbnail: slimMain},
                {original: slimUno, thumbnail: slimUno}
            ])
        }else if(page=== "hair") {
            setImages([
                {original: hairMain, thumbnail: hairMain},
                {original: hairUno, thumbnail: hairUno},
                {original: hairDos, thumbnail: hairDos}
            ])
        }else {
            setImages([
                {original: sexMain, thumbnail: sexMain},
                {original: sexUno, thumbnail: sexUno},
                {original: sexDos, thumbnail: sexDos},
                {original: sexTres, thumbnail: sexTres},
            ])
        }
    }, [])

    useEffect(()=> {
        if(wilayaFId!== "" && produitFId!== "" && qte> 0) {
            let transport= wilayas.find(obj => obj.wilayaId === wilayaFId).pu
            let prix= produits.find(obj => obj.produitId === produitFId).prix
            setTotal((prix * qte) + transport)
        }else {
            setTotal(0)
        }
    }, [wilayaFId, produitFId, qte])

  return (
    <div className= "Order">
        <Nav />

        <div className="Order_main">
            <div className="Order_main_gallery">
                <ImageGallery 
                    
                    items={images} 
                    showPlayButton= {false}
                    showFullscreenButton= {true}
                    showNav= {true}
                    showBullets= {true}
                    autoPlay= {true}
                    slideInterval= {3000}
                    slideDuration= {1000}
                />
            </div>
            <div className="Order_main_content" onSubmit= {addCommande}>
                <form action="" className="Order_main_form">
                    <TextField value= {nom} onChange= {(e)=> {setNom(e.target.value)}} variant= "outlined" style= {{width: "90%"}} label= "اللقب" />
                    <TextField value= {prenom} onChange= {(e)=> {setPrenom(e.target.value)}} variant= "outlined" style= {{width: "90%"}} label= "الإسم" />
                    <TextField value= {numTel} onChange= {(e)=> {setNumTel(e.target.value)}} variant= "outlined" style= {{width: "90%"}} label= "رقم الهاتف" />
                    <FormControl style= {{width: "90%"}}>
                        <InputLabel id="demo-simple-select-label">الولاية</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value= {wilayaFId}
                            label= "نوع الطلب"
                            onChange={(e)=> {setWilayaFId(e.target.value)}}
                        >
                            <MenuItem value= "">اختري الولاية</MenuItem>
                            {
                                wilayas.map((e)=> {
                                    return (
                                        <MenuItem value= {e.wilayaId}>{e.code} {e.wilaya_ar}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl style= {{width: "90%"}}>
                        <InputLabel id="demo-simple-select-label">المنتجات</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value= {produitFId}
                            label= "نوع الطلب"
                            onChange={(e)=> {setProduitFId(e.target.value)}}
                        >
                            <MenuItem value="">اختري منتجك</MenuItem>
                            {
                                produits.map((e)=> {
                                    return (
                                        <MenuItem value= {e.produitId}>{e.produitNom} {e.prix}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <div className="" style= {{width: "90%", display: "flex", justifyContent: "center", gap: ".5rem", padding: ".5rem", alignItems: "center"}}>
                        <RemoveCircleIcon id= "remove_btn" onClick= {dec} />
                        <TextField 
                            label= "الكمية" type= 'number' value= {qte} onChange= {(e)=> {setQte(e.target.value)}} 
                            style= {{width: "60%", direction: "rtl"}}
                        />
                        <AddCircleIcon id= "add_btn" onClick= {()=> {setQte(old=> old + 1)}} />
                    </div>
                    <Button id= "btn" type= 'submit' variant= "contained" disabled= {(nom=== "" || prenom=== "" || numTel=== "" || qte=== 0 || wilayaFId=== "" || produitFId=== "")&& true} >
                        تأكيد الطلب
                    </Button>
                </form>

                <div className="Order_main_fact">
                    <h1 style= {{color: "#cc0000", alignSelf: "center", textAlign: "center"}}>
                        ملخص طلبك، سيدتي
                    </h1>
                    <div className= "Order_main_fact_entete">
                        <h3>اللقب: <span style= {{color: "#cc0000"}}>{nom}</span></h3>
                        <h3>الإسم: <span style= {{color: "#cc0000"}}>{prenom}</span></h3>
                        <h3>رقم الهاتف: <span style= {{color: "#cc0000"}}>{numTel}</span></h3>
                        <h3>الولاية:
                            {
                                (wilayas.find(obj => obj.wilayaId === wilayaFId))
                                &&<span style= {{color: "#cc0000"}}> {(wilayas.find(obj => obj.wilayaId === wilayaFId)).wilaya_ar}</span>
                            }
                        </h3>
                    </div>
                    
                    <div className="Order_main_fact_prix">
                        <h3>المنتج: 
                            {
                                (produits.find(obj => obj.produitId === produitFId))
                                &&<span style= {{color: "#cc0000"}}> {(produits.find(obj => obj.produitId === produitFId)).nom} </span>
                            }
                        </h3>
                        <h3>سعر المنتج: 
                            {
                                (produits.find(obj => obj.produitId === produitFId))
                                &&<span style= {{color: "#cc0000"}}> {(produits.find(obj => obj.produitId === produitFId)).prix} </span>
                            }
                        </h3>
                        <h3>سعر التوصيل: 
                            {
                                (wilayas.find(obj => obj.wilayaId === wilayaFId))
                                &&<span style= {{color: "#cc0000"}}> {(wilayas.find(obj => obj.wilayaId === wilayaFId)).pu} </span>
                            }
                        </h3> 
                        <h3>إجمالي الطلب: 
                            <span style= {{color: "#cc0000"}}>
                                <> {total}</>
                            </span>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        {
            msgSucc
            &&<Stack id= "alert" spacing={2}>
                <Alert severity="success">تم ارسال الطلب بنجاح، انتظروا اتصالنا</Alert>
            </Stack>
        }
        {
            msgErr
            &&<Stack id= "alert" spacing={2}>
                <Alert severity="error">تم وقوع خلل، يرجى اعادة المحاولة</Alert>
            </Stack>
        } 
        <Footer />
    </div>
  )
}

export default OrderInter