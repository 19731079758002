import React from 'react'
import OrderInter from '../component/OrderInter'

function Hair() {
  return (
    <div>
        <OrderInter page= "hair" />
    </div>
  )
}

export default Hair