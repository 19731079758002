import Nav from "../component/Nav"
import '../App.css'
import Footer from '../component/Footer'
import Main from "../component/Main"
import { useEffect } from "react"



function Home() {




  
  return (
    <div className= "Home">
        <Nav active= {"home"} />
        
        <Main />

        <Footer />
    </div>
  )
}

export default Home