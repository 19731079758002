import '../App.css'
import logo from '../images/logo.jpg'
import InstagramIcon from '@mui/icons-material/Instagram'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

function Footer() {
  return (
    <footer className= 'Footer'>
      <div className="Footer_first">
        <img src= {logo} alt="" />
        <div className="Footer_first_socials">
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ibtissam_fo?igsh=MWE3cTQwczhxeDE0dw==">
            <InstagramIcon id= "footer_icon" />
            <h6>ibtissam_fo</h6>
          </a>
          <a href= "mailto:ibtissamfodil11@gmail.com" target="_blank" rel="noopener noreferrer">
            <EmailIcon id= "footer_icon" />
            <h6>ibtissamfodil11@gmail.com</h6>
          </a>
          <a target="_blank" rel="noreferrer" href="tel:+213774356904">
            <LocalPhoneIcon id= "footer_icon" />
            <h6>+213774356904</h6>
          </a>
        </div>
          
      </div>
      <div className="Footer_second">
        <h6>Flora copyright ©️ 2024</h6>
      </div>
      
    </footer>
  )
}

export default Footer