import OrderInter from "../component/OrderInter"


function Sex() {
  return (
    <div>
        <OrderInter page= "sexual" />
    </div>
  )
}

export default Sex