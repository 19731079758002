import { useEffect } from 'react'
import '../App.css'
import logo from '../images/logo.jpg'
import Button from '@mui/material/Button'
import { motion } from "framer-motion"
import AOS from 'aos'
import sex from '../images/sex/sex_main.jpg'
import hair from '../images/hair/hair_main.jpg'
import slim from '../images/slim/slim_main.jpg'
import fat from '../images/fat/fat_main.jpg'
import { Link } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import anUno from '../images/analyse/an_uno.jpg'
import anDos from '../images/analyse/an_dos.jpg'
import anTres from '../images/analyse/an_tres.jpg'
import anCuatro from '../images/analyse/an_cuatro.jpg'
import clUno from '../images/clients/uno.jpg'
import clDos from '../images/clients/dos.jpg'
import clTres from '../images/clients/tres.jpg'
import clCuatro from '../images/clients/cuatro.jpg'
import clCinq from '../images/clients/cinq.jpg'

function Main() {

    const analyseImages= [
        {original: anUno, thumbnail: anUno},
        {original: anDos, thumbnail: anDos},
        {original: anTres, thumbnail: anTres},
        {original: anCuatro, thumbnail: anCuatro}
    ]
    const clientsImages= [
        {original: clUno, thumbnail: clUno},
        {original: clDos, thumbnail: clDos},
        {original: clTres, thumbnail: clTres},
        {original: clCuatro, thumbnail: clCuatro},
        {original: clCinq, thumbnail: clCinq}
    ]


    useEffect(()=> {
        AOS.init({duration: 1000})
    }, [])
  return (
    <main className= "Home_main">
        <div className="Home_main_header">
            <motion.h1 transition= {{ duration: .5 }} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                منتجات أصلية و بمقاييس عالمية
            </motion.h1>
            <motion.h5 transition= {{ duration: .5 }} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                تصفحوا قائمة مجموعات المنتجات
            </motion.h5>
        </div>
        <motion.div className="Home_main_content" transition= {{ delay: .5, duration: .5 }} initial={{y: 500, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 0, opacity: 1}}>
            <motion.div className="Home_main_gamme" >
                <img src= {fat} alt="" />
                <h3>
                    مجموعة المسمنة
                </h3>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis, dolorum.
                </p>
                <Link style= {{width: "100%"}} to= 'fat_products'>
                    <Button variant="contained" id= "link">
                        زيارة و طلب
                    </Button>
                </Link>
            </motion.div>
            <motion.div className="Home_main_gamme"  >
                <img src= {slim} alt="" />
                <h3>
                    مجموعة المنحفة
                </h3>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam, inventore.</p>
                <Link style= {{width: "100%"}} to= 'slim_products'>
                    <Button variant="contained" id= "link">
                        زيارة و طلب
                    </Button>
                </Link>
            </motion.div>
            <motion.div className="Home_main_gamme">
                <img src= {hair} alt="" />
                <h3>
                    مجموعة العناية بالشعر
                </h3>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate, quos.</p>
                <Link style= {{width: "100%"}} to= 'hair_products'>
                    <Button variant="contained" id= "link">
                        زيارة و طلب
                    </Button>
                </Link>
            </motion.div>
            <motion.div className="Home_main_gamme" >
                <img src= {sex} alt="" />
                <h3>
                    مجوعة القوة الجنسية
                </h3>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate, quos.</p>
                <Link style= {{width: "100%"}} to= 'sexual_products'>
                    <Button variant="contained" id= "link">
                        زيارة و طلب
                    </Button>
                </Link>
            </motion.div>
        </motion.div>
        <div className="Home_main_second">
            <h5 style= {{textAlign: "center"}}>
            مبدأنا الثقة و ضمان الجودة،  صناعة وفق المعايير الصحية، معتمد من قبل المختبرات الطبية   
            </h5>
            <div className="Home_main_second_gallery">
                <ImageGallery 
                    items={analyseImages} 
                    showPlayButton= {false}
                    showFullscreenButton= {true}
                    showNav= {true}
                    showBullets= {true}
                    autoPlay= {true}
                    slideInterval= {3000}
                    slideDuration= {1000}
                />
            </div>
        </div>
        <div className="Home_main_second">
            <h5 style= {{textAlign: "center"}}>
                شهادات المستخدمات لمنتجاتنا
            </h5>
            <div className="Home_main_second_gallery">
                <ImageGallery 
                    items={clientsImages} 
                    showPlayButton= {false}
                    showFullscreenButton= {true}
                    showNav= {true}
                    showBullets= {true}
                    autoPlay= {true}
                    slideInterval= {3000}
                    slideDuration= {1000}
                />
            </div>
        </div>
    </main>
  )
}

export default Main