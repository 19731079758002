import { useState, useEffect, useRef } from 'react'
import '../App.css'
import logo from '../images/logo.jpg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Squash as Hamburger, Squash } from 'hamburger-react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Link } from "react-router-dom"
import SearchIcon from '@mui/icons-material/Search'
import TextField  from '@mui/material/TextField'

function Nav({active}) {
    const menuRef= useRef()
    const btnRef= useRef()

    const [hamOpen, setHamOpen]= useState(false)

    const mobile= useMediaQuery('(width <= 800px)')

    
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClicks)
          return () => {
            document.removeEventListener("mousedown", handleOutsideClicks)
        }
    }, [hamOpen])
    
    const handleOutsideClicks =(event)=>{
        const ham= document.querySelector('.ham_menu')
        const hamChild= document.querySelector('.hamburger-react')
        const hamGrand= document.querySelector('.hamburger-react div')
        if(hamOpen && menuRef.current && !menuRef.current.contains(event.target) && !btnRef.current.contains(event.target)){
            setHamOpen(false)
        }
    }

    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        if (window.scrollY > lastScrollY) {
        setShow(false)
        } else {
        setShow(true) 
        }
        setLastScrollY(window.scrollY)
    }

    useEffect(() => {
      window.addEventListener('scroll', controlNavbar);
      return () => {
      window.removeEventListener('scroll', controlNavbar);
      }
    }, [lastScrollY])
  return (
    <>
    {
        
        <div className= "Nav" style= {{height: show? "10vh": 0, opacity: show? 1: 0}}>
            <Link style= {{height: "100%", width: "100px", display: "flex", justifyContent: "center", alignItems: "center", flex: .1}} to= '/'>
                <img src= {logo} alt="" />
            </Link>
            {
                (!mobile) &&
                <>
                    <ul>
                        <Link to= '/' >
                            <li style= {{color: (active=== "home") && "#cc0000"}}>الرئيسية</li>
                        </Link>
                        <Link to= '/about'>
                            <li style= {{color: (active=== "about") && "#cc0000"}}>حولنا</li>
                        </Link>
                        <Link to= '/contact'>
                            <li style= {{color: (active=== "contact") && "#cc0000"}}>اتصلي بنا</li>
                        </Link>
                    </ul>
                    <Link style= {{opacity: 0, pointerEvents: "none", height: "100%", width: "100px", display: "flex", justifyContent: "center", alignItems: "center", flex: .1}} to= '/'>
                        <img src= {logo} alt="" />
                    </Link>
                </>
            }   
            {
                mobile && 
                <div ref= {btnRef} className="">
                    <Hamburger  toggled={hamOpen} onToggle={() => setHamOpen(!hamOpen)} />
                </div>
                
            }

            {
                (mobile) &&
                <div ref= {menuRef} style= {{width: hamOpen&& "50%", opacity: hamOpen&& 1}} className="ham_menu">
                    <ul>
                        
                        <li><Link style= {{color: (active=== "home") && "#cc0000"}} to= '/'>الرئيسية</Link></li>
                       
                        
                        <li><Link style= {{color: (active=== "about") && "#cc0000"}} to= '/about'>حولنا</Link></li>
                        
                        
                        <li><Link style= {{color: (active=== "contact") && "#cc0000"}} to= '/contact'>اتصلي بنا</Link></li>
                        
                    </ul>
                </div>
            }
        </div>
    }
    </>
  )
}

export default Nav