import React from 'react'
import OrderInter from '../component/OrderInter'

function Slim() {
  return (
    <div>
        <OrderInter page= "slim" />
    </div>
  )
}

export default Slim