import Nav from "../component/Nav"
import '../styles/Abc.css'
import TextField  from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Footer from "../component/Footer"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import { motion, useScroll } from "framer-motion"
import { useState } from "react"
import Axios from 'axios'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'



function Contact() {

    const [nom, setNom]= useState("")
    const [email, setEmail]= useState("")
    const [sujet, setSujet]= useState("")
    const [msg, setMsg]= useState("")
    const [numTel, setNumTel]= useState("")
    const [msgSucc, setMsgSucc]= useState(false)
    const [msgErr, setMsgErr]= useState(false)


    const contact= (e)=> {
        e.preventDefault()
        Axios.post(`https://gnmc-dz.com/backtest/addContact`, 
            {nom: nom, numTel: numTel, email: email, sujet: sujet, message: msg}
        )
        .then(res=> {
            setMsgSucc(true)
            setNom("")
            setEmail("")
            setMsg("")
            setNumTel("")
            setSujet("")
            setTimeout(() => {
                setMsgSucc(false)
            }, 5000);
        })
        .catch(err=> {
            setMsgErr(true)
            setTimeout(() => {
                setMsgErr(false)
            }, 5000);
        })
    }

  return (
    <div className= "Contact">
        <Nav active= {"contact"} />
        <div className="Contact_main">
            <motion.form action="" onSubmit= {contact} transition= {{ duration: .5 }} initial={{scale: 0, opacity: 1}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 0}} >
                <motion.h2 style= {{color: "#cc0000", textAlign: "center"}} transition= {{ delay: .5, duration: .5 }} initial={{y: -1000, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: -1000, opacity: 0}}>نحن هنا في خدمتكم، و ضمان راحتكم</motion.h2>
                <TextField value= {nom} onChange= {(e)=> {setNom(e.target.value)}} style= {{width: "90%"}} id= "contact_input" label="الإسم" variant="outlined" />
                <TextField value= {numTel} type= "number" onChange= {(e)=> {setNumTel(e.target.value)}} style= {{width: "90%"}} id= "contact_input" label="رقم الهاتف" variant="outlined" />
                <TextField value= {email} onChange= {(e)=> {setEmail(e.target.value)}} style= {{width: "90%"}} id= "contact_input" label="البريد الالكتروني" variant="outlined" />
                <TextField value= {sujet} onChange= {(e)=> {setSujet(e.target.value)}} style= {{width: "90%"}} id= "contact_input" label="الموضوع" variant="outlined" />
                <TextareaAutosize value= {msg} onChange= {(e)=> {setMsg(e.target.value)}} name='message' id= "contact_message" 
                    style= {{
                        maxWidth: "90%", width: "90%", maxHeight: "150px", height: "100px", overflowY: "scroll", 
                        borderRadius: "10px", border: "#143B38", 
                    }} 
                    maxRows= {10}
                    placeholder= "رسائلكم و استفساراتكم"
                />
                <Button 
                    id= "btn" variant= "contained" type= 'submit'
                    disabled= {(nom=== "" || email=== "" || msg=== "" || sujet=== "" || numTel=== "")&& true}
                >
                    تأكيد
                </Button>
            </motion.form>
        </div>
        {
            msgSucc
            &&<Stack id= "alert" spacing={2}>
                <Alert severity="success">تم ارسال الرسالة بنجاح، شكرا على ثقتكم</Alert>
            </Stack>
        }
        {
            msgErr
            &&<Stack id= "alert" spacing={2}>
                <Alert severity="error">تم وقوع خلل، يرجى اعادة المحاولة</Alert>
            </Stack>
        } 
        <Footer />
    </div>
  )
}

export default Contact